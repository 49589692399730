import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/16/solid';
import { ETranslationType } from '../../Translations/enum/translations.enums';
import { ETranslationEntity } from '../../Translations/types/translationApply.types';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';

interface IRichTextTypePlaceholder {
    placeholder?: string;
    labelTranslationKey?: string;
}

export const LabelTypePlaceholder: React.FC<IRichTextTypePlaceholder> = (props) => {
    const { placeholder = 'Type ...', labelTranslationKey } = props;

    const { applyTranslation } = useTranslationApply();

    // Note: pointer-events-none needs to stay, because of useTranslateObserver problems.
    return (
        <span
            data-test-id="label-placeholder"
            className="inline-flex h-full flex-row items-center justify-start gap-1 px-1 text-neutral-300"
            {...applyTranslation({
                key: labelTranslationKey,
                type: ETranslationType.static,
                entity: ETranslationEntity.text,
            })}
        >
            <PencilSquareIcon className="pointer-events-none h-4 text-neutral-300" />
            <span className="pointer-events-none text-xs font-light">{placeholder}</span>
        </span>
    );
};
