import { ITextField } from '../TextField';
import { useMemo } from 'react';
import { IValidation } from '../interfaces/textField.interfaces';
import { urlRegExp } from '../../../../../regex/urlRegExp';
import { emailRegExp } from '../../../../../regex/emailRegExp';
import { relativeUrlRegExp } from '../../../../../regex/relativeUrlRegExp';
import { integerRegExp } from '../../../../../regex/integerRegExp';
import { phoneRegExp } from '../../../../../regex/phoneRegExp';
import { mobileRegExp } from '../../../../../regex/mobileRegExp';
import { useTranslation } from '../../../Translations/hooks/useTranslation';

export const useTextFieldValidation = (value: string | null, props: ITextField) => {
    const { type, required, validation, validationMessage, validationRegEx } = props;

    const forbiddenChars: string[] = useMemo((): string[] => {
        return ['<', '>', '\\', '{', '}'];
    }, []);

    const { getT } = useTranslation();

    const validationState = useMemo((): IValidation => {
        // Check for böse
        if (type !== 'password') {
            let forbidden: IValidation | null = null;
            forbiddenChars.forEach((forbiddenItem) => {
                if (value && value.includes(forbiddenItem)) {
                    const chars = forbiddenChars.join('  ');

                    forbidden = {
                        isValid: false,
                        message: `Forbidden characters ${chars}`,
                    };
                }
            });
            if (forbidden) return forbidden;
        }

        // Check for required
        if (required && (value === '' || !value)) {
            return {
                isValid: false,
                message: getT('controlTextIsRequired') ?? 'Field is required',
            };
        }

        // Actual validation
        if ((value !== null && !required && value !== '') || required) {
            switch (validation) {
                case 'email':
                    if (!emailRegExp.test(value as string)) {
                        return {
                            isValid: false,
                            message:
                                validationMessage ??
                                'Please enter a valid email address, e. g. yourname@example.com',
                        };
                    }
                    break;
                case 'phone':
                    if (!phoneRegExp.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? 'Use this format: +XX XXXX XXXXXX',
                        };
                    }
                    break;
                case 'mobile':
                    if (!mobileRegExp.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? 'Use this format: +XXXXXXXXXXXX',
                        };
                    }
                    break;
                case 'number':
                    if (!integerRegExp.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? 'Only integer allowed',
                        };
                    }
                    break;
                case 'url':
                    if (!urlRegExp.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? "Doesn't look like an URL",
                        };
                    } else {
                        if (value && !value.trim().toLowerCase().startsWith('https://')) {
                            return {
                                isValid: false,
                                message: 'URL must start with https (SSL)',
                            };
                        }
                    }
                    break;
                case 'urlAndRelative':
                    if (
                        !urlRegExp.test(value as string) &&
                        !relativeUrlRegExp.test(value as string)
                    ) {
                        return {
                            isValid: false,
                            message:
                                validationMessage ?? "Doesn't look like an URL or relative path",
                        };
                    }
                    break;
                case 'custom':
                    if (validationRegEx && !validationRegEx.test(value as string)) {
                        return {
                            isValid: false,
                            message: validationMessage ?? "Doesn't look like an e-mail",
                        };
                    }
                    break;
            }
        }

        return {
            isValid: true,
            message: '',
        };
    }, [
        forbiddenChars,
        getT,
        required,
        type,
        validation,
        validationMessage,
        validationRegEx,
        value,
    ]);

    return { validationState };
};
