import { useCallback } from 'react';
import { ITextField } from '../TextField';

export const useTextFieldManipulation = (props: ITextField) => {
    const { min, allowNegative, max } = props;

    const manipulateInputNumberValue = useCallback(
        (inputValue: string): string => {
            let returnValue = inputValue;

            // Cannot be 0 or smaller
            if (Number(returnValue) < 1 && allowNegative !== true) {
                if (min === undefined && returnValue === '') {
                    returnValue = '';
                } else {
                    returnValue = '1';
                }
            }

            if (min !== undefined && Number(returnValue) < min) {
                return String(min);
            }

            if (max !== undefined && Number(returnValue) > max) {
                return String(max);
            }

            // Ceil down to absolute number
            if (Number(returnValue) > 0) {
                returnValue = String(Math.ceil(Number(returnValue)));
                return String(returnValue);
            }

            return returnValue;
        },
        [allowNegative, max, min]
    );

    return { manipulateInputNumberValue };
};
