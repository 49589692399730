import React, { ReactElement, useMemo } from 'react';
import { Label } from '../../../typo/label/Label';
import { ETranslationEntity } from '../../../Translations/types/translationApply.types';
import { useTranslationApply } from '../../../Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../Translations/hooks/useTranslation';
import { ETranslationType } from '../../../Translations/enum/translations.enums';
import { LabelTypePlaceholder } from '../../../typo/label/LabelTypePlaceholder';
import { useAdminControlsState } from '../../../elements/adminControls/state/adminControls.state';
import { useLocalisationState } from '../../../Translations/state/localisation.state';
import { useUserGenericFieldsQuery } from '../../../hooks/user/useUserGenericFieldsQuery';

export interface ITextFieldLabel {
    required?: boolean;
    label?: string;
    labelTranslationKey?: string;
    labelTranslationType?: ETranslationType;
    labelTag?: ReactElement<unknown>;
    testId?: string;
}

// Config interface to add type safety
interface IFieldLabelConfig {
    label?: string;
    labelTranslation?: {
        baseTranslation?: string;
        languageCode?: string;
        translations?: Array<{
            languageCode: string;
            translation: string;
        }>;
    };
    [key: string]: unknown;
}

export const TextFieldLabel: React.FC<ITextFieldLabel> = (props) => {
    const {
        required,
        label,
        labelTranslationKey,
        labelTranslationType = ETranslationType.static,
        labelTag,
        testId,
    } = props;

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();
    const { languageControls } = useAdminControlsState();

    // Fetch user fields if needed for userFieldLabel
    const { data: userFields } = useUserGenericFieldsQuery({
        enabled: labelTranslationType === ETranslationType.userFieldLabel && !!labelTranslationKey,
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    // Get the current user's language
    const { getCurrentLanguage } = useLocalisationState();
    const currentLanguage = getCurrentLanguage().languageCode;

    // Get the label text based on translation type
    const labelText = useMemo(() => {
        // Handle static translations as before
        if (labelTranslationType === ETranslationType.static && labelTranslationKey) {
            return getT(labelTranslationKey);
        }

        // Handle user field labels - get them from config with language selection
        if (
            labelTranslationType === ETranslationType.userFieldLabel &&
            labelTranslationKey &&
            userFields
        ) {
            const userField = userFields.find((field) => field.fieldName === labelTranslationKey);

            if (userField && userField.config) {
                try {
                    const config: IFieldLabelConfig =
                        typeof userField.config === 'string'
                            ? (JSON.parse(userField.config) as IFieldLabelConfig)
                            : (userField.config as unknown as IFieldLabelConfig);

                    // Check for labelTranslation in config
                    if (config.labelTranslation) {
                        // First try to find a translation for the current language
                        if (
                            config.labelTranslation.translations &&
                            Array.isArray(config.labelTranslation.translations)
                        ) {
                            const currentLanguageTranslation =
                                config.labelTranslation.translations.find(
                                    (t) => t.languageCode === currentLanguage && t.translation
                                );

                            if (currentLanguageTranslation?.translation) {
                                return currentLanguageTranslation.translation;
                            }
                        }

                        // If no translation found for current language, fall back to baseTranslation
                        if (config.labelTranslation.baseTranslation) {
                            return config.labelTranslation.baseTranslation;
                        }
                    }

                    // If no translations available, use label property
                    if (config.label) {
                        return config.label;
                    }
                } catch (e) {
                    // If JSON parsing fails, fall back to field name
                    return labelTranslationKey;
                }
            }

            return labelTranslationKey;
        }

        return label ?? '';
    }, [label, labelTranslationKey, labelTranslationType, userFields, getT, currentLanguage]);

    return (
        <div className="relative flex flex-row">
            {(label || labelTranslationKey) && (
                <Label required={required}>
                    <span
                        data-test-id={testId ? `text-field-label-${testId}` : 'text-field-label'}
                        className="relative flex flex-row items-center gap-2"
                        {...applyTranslation({
                            key: labelTranslationKey,
                            type: labelTranslationType,
                            entity: ETranslationEntity.text,
                        })}
                    >
                        {labelText} {labelTag && labelTag}
                    </span>
                </Label>
            )}
            {languageControls && labelTranslationKey && !label && (
                <>
                    {labelTranslationKey &&
                        (!getT(labelTranslationKey) || getT(labelTranslationKey)?.length === 0) && (
                            <LabelTypePlaceholder labelTranslationKey={labelTranslationKey} />
                        )}
                </>
            )}
        </div>
    );
};
