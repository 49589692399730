import { useApi } from '../api/useApi';
import { useQuery } from '@tanstack/react-query';
import { IGenericFieldBE } from '../../../../interfaces/user/IGenericFieldBE';
import { gql } from 'graphql-request';

interface UserGenericFieldsQueryOptions {
    enabled?: boolean;
    staleTime?: number;
}

export const useUserGenericFieldsQuery = (options?: UserGenericFieldsQueryOptions) => {
    const { postGql } = useApi();

    return useQuery({
        queryKey: ['userGenericFields'],
        queryFn: async () => {
            const { userGenericFields } = await postGql<{ userGenericFields: IGenericFieldBE[] }>(
                'userGenericFields',
                gql`
                    query {
                        userGenericFields {
                            fieldName
                            group
                            config
                            defaultValue
                            tenantID
                            order
                            translations {
                                languageCode
                                context
                                baseTranslation
                                translationKey
                                group
                                tags
                            }
                        }
                    }
                `
            );

            return userGenericFields;
        },
        enabled: options?.enabled,
        staleTime: options?.staleTime,
    });
};
