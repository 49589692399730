import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useToggle } from 'react-use';
import { ValidationMessage } from '../../typo/validationMessage/ValidationMessage';

import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { EyeIcon, EyeSlashIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { IconButton } from '../button/IconButton';
import { useThemeStyles } from '../../theme/TenantTheme/hooks/useThemeStyles';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { useTranslation } from '../../Translations/hooks/useTranslation';
import { useTextFieldValidation } from './hooks/useTextFieldValidation';
import { useTextFieldManipulation } from './hooks/useTextFieldManipulation';
import { ETranslationType } from '../../Translations/enum/translations.enums';
import { TextFieldLabel } from './elements/TextFieldLabel';

export interface ITextField {
    id?: string;
    testId?: string;
    className?: string;
    classNameInput?: string;
    type?: 'password' | 'text' | 'number' | 'color' | 'date';
    required?: boolean;
    label?: string;
    labelTranslationKey?: string;
    labelTranslationType?: ETranslationType;
    labelDescription?: string;
    labelTag?: ReactElement<unknown>;
    placeholder?: string;
    placeholderTranslationKey?: string;
    simulateEmptyLabel?: boolean;
    autocomplete?: string;
    /**
     * @deprecated Use `value` instead. `defaultValue` will be removed in a future release.
     */
    defaultValue?: string;
    value?: string | null;
    onChange?: (value: string) => void;
    onPaste?: (value: string) => string;
    onBlur?: (value: string) => void;
    onClearButton?: () => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    manipulation?: (value: string) => string;
    iconStart?: ReactElement<unknown>;
    iconEnd?: ReactElement<unknown>;
    iconSpin?: boolean;
    validation?:
        | 'email'
        | 'url'
        | 'urlAndRelative'
        | 'number'
        | 'phone'
        | 'custom'
        | 'password'
        | 'mobile';
    validationRegEx?: RegExp;
    validationMessage?: string;
    showValidation?: boolean;
    reportValidation?: (id: string, isValid: boolean) => void;
    /**
     * @deprecated Remove it, it doesn't have a function anymore.
     */
    alignWithFormFields?: boolean;
    disabled?: boolean;
    min?: number;
    max?: number;
    step?: number;
    fancyNumbers?: boolean;
    allowNegative?: boolean;
    clearable?: boolean;
    maxLength?: number;
    showFillMe?: boolean;
}

type ITextFieldProps = Omit<ITextField, 'ref'> & React.RefAttributes<HTMLInputElement>;

export const TextField: React.FC<ITextFieldProps> = React.forwardRef<
    HTMLInputElement,
    ITextFieldProps
>((props, ref) => {
    const {
        id = '',
        type = 'text',
        required = false,
        className,
        classNameInput,
        testId,
        label,
        labelTranslationKey,
        labelTranslationType = ETranslationType.static,
        labelDescription,
        labelTag,
        placeholder,
        placeholderTranslationKey,
        autocomplete,
        defaultValue,
        value,
        onChange,
        onPaste,
        onBlur,
        onKeyDown,
        onClearButton,
        iconStart,
        iconEnd,
        iconSpin = false,
        validationMessage,
        reportValidation,
        showValidation = false,
        manipulation,
        simulateEmptyLabel = false,
        disabled,
        min,
        step,
        fancyNumbers,
        allowNegative,
        clearable,
        maxLength,
        showFillMe = false,
    } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);
    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();
    const { applyStyles } = useThemeStyles();
    const [showPassword, toggleShowPassword] = useToggle(false);

    const valueLocal: string | null = useMemo(() => {
        return value ?? defaultValue ?? null;
    }, [defaultValue, value]);

    const { validationState } = useTextFieldValidation(valueLocal, props);

    const { manipulateInputNumberValue } = useTextFieldManipulation(props);

    useEffect(() => {
        if (reportValidation && validationState) {
            reportValidation(id ?? '-', validationState.isValid ?? true);
        }
    }, [id, reportValidation, validationState]);

    const showLabelDescription = useMemo(() => {
        if (labelDescription && labelDescription !== '') {
            return !showValidation;
        }

        return false;
    }, [labelDescription, showValidation]);

    const validationStyles = useMemo(() => {
        return validationState.isValid || !showValidation
            ? 'border-[rgba(5,15,38,0.24)]'
            : 'border-danger';
    }, [showValidation, validationState.isValid]);

    const borderStyles = useMemo(() => {
        if (showValidation && !validationState.isValid) return 'border-danger';
        return 'border-neutral-300';
    }, [showValidation, validationState.isValid]);

    const inputType = useMemo(() => {
        switch (type) {
            case 'password':
                return showPassword ? 'text' : 'password';
            default:
                return type;
        }
    }, [showPassword, type]);

    const inputWrapperGrid = useMemo(() => {
        if (iconStart) return 'grid grid-cols-[50px,auto]';

        return 'flex flex-row';
    }, [iconStart]);

    const roundedStyles = useMemo(() => {
        switch (tenantTheme.border?.radius) {
            case 'default':
                if (iconStart) return 'rounded-tr rounded-br';
                return 'rounded';
            case 'strong':
                if (iconStart) return 'rounded-tr-lg rounded-br-lg';
                return 'rounded-lg';
        }

        return '';
    }, [iconStart, tenantTheme.border?.radius]);

    const fancyStyles = useMemo(() => {
        return fancyNumbers ? 'text-3xl font-medium p-3 h-auto' : '';
    }, [fancyNumbers]);

    const placeholderStyles = useMemo(() => {
        const textStyles = fancyNumbers
            ? 'placeholder:text-xl placeholder:font-medium'
            : 'placeholder:text-sm md:placeholder:text-base';

        return `placeholder:tracking-wide placeholder:text-body-light ${textStyles}`;
    }, [fancyNumbers]);

    const handleBlur = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            let manipulateResult = e.target.value;

            if (type === 'number') {
                manipulateResult = manipulateInputNumberValue(manipulateResult ?? '');
            } else {
                manipulateResult = manipulation?.(manipulateResult ?? '') ?? manipulateResult;
            }

            if (onBlur) {
                onBlur(manipulateResult);
            }
        },
        [manipulateInputNumberValue, manipulation, onBlur, type]
    );

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(e.target.value);
            }
        },
        [onChange]
    );

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        if (onPaste) {
            // Prevent the default paste behavior
            event.preventDefault();

            // Get the text content from the clipboard
            const text = event.clipboardData.getData('text/plain');

            // Clean the text to keep only digits
            const cleanedText = onPaste(text);

            if (onChange) {
                onChange(cleanedText);
            }
        }
    };

    const fillMeStyles = useMemo(() => {
        if (!showFillMe) return '';

        if ((showFillMe && !valueLocal) || valueLocal === '') {
            return 'bg-danger/10';
        }

        return '';
    }, [showFillMe, valueLocal]);

    return (
        <div
            data-test-id={
                testId ? `text-field-component-wrapper-${testId}` : 'text-field-component-wrapper'
            }
            className={`relative flex w-full flex-col gap-0.5 ${className ?? ''}`}
        >
            {!label && simulateEmptyLabel && <span className="block h-4"></span>}

            <TextFieldLabel
                labelTranslationKey={labelTranslationKey}
                label={label}
                labelTranslationType={labelTranslationType}
                required={required}
                testId={testId}
                labelTag={labelTag ?? undefined}
            />

            <div className={`${inputWrapperGrid} relative w-full gap-0`}>
                {iconStart && (
                    <div className="flex items-center justify-center rounded-tl rounded-bl bg-neutral-300">
                        <span
                            data-test-id={testId ? `icon-start-${testId}` : 'icon-start'}
                            className="w-6 text-white"
                        >
                            {iconStart}
                        </span>
                    </div>
                )}
                <input
                    ref={ref}
                    className={`relative h-10 w-full ${fillMeStyles} ${placeholderStyles} ${roundedStyles} ${validationStyles} ${borderStyles} ${fancyStyles} ${
                        disabled ? 'cursor-not-allowed' : ''
                    } ${iconEnd || type === 'password' ? 'pr-10' : ''} ${classNameInput}`}
                    style={{
                        ...(validationState.isValid || !showValidation
                            ? {
                                  ...applyStyles({
                                      textColor: 'colors.body.DEFAULT',
                                  }),
                                  borderStyle: 'solid',
                                  borderWidth: '1px',
                                  borderColor: '#d4d4d4',
                              }
                            : {
                                  ...applyStyles({
                                      textColor: 'colors.body.DEFAULT',
                                  }),
                                  borderStyle: 'solid',
                                  borderWidth: '1px',
                                  borderColor: tenantTheme.colors.danger.DEFAULT,
                              }),
                    }}
                    type={inputType}
                    maxLength={maxLength ? maxLength : undefined}
                    required={required}
                    aria-required={required}
                    value={valueLocal ?? ''}
                    {...applyTranslation({
                        key: placeholderTranslationKey,
                    })}
                    placeholder={
                        (placeholderTranslationKey && getT(placeholderTranslationKey)) ??
                        placeholder ??
                        ''
                    }
                    autoComplete={autocomplete ?? 'off'}
                    onPaste={handlePaste}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                        if (onKeyDown) onKeyDown(e);
                    }}
                    name={autocomplete}
                    id={id ?? undefined}
                    data-test-id={testId ?? ''}
                    key={''}
                    disabled={disabled}
                    min={(min ?? allowNegative) ? undefined : 1}
                    step={step ?? undefined}
                />

                {type === 'password' && (
                    <button
                        type="button"
                        aria-hidden={true}
                        className="absolute top-2 flex w-6 items-center justify-center text-neutral-500/50 right-1.5"
                        onClick={() => toggleShowPassword()}
                        data-test-id={`${testId ?? ''}-visibilityToggle`}
                    >
                        {!showPassword && <EyeSlashIcon className="text-lg"></EyeSlashIcon>}
                        {showPassword && <EyeIcon className="text-lg" />}
                    </button>
                )}

                {iconEnd && (
                    <div className="absolute right-2 flex h-full w-max items-center justify-center">
                        <span
                            data-test-id={testId ? `icon-end-${testId}` : 'icon-end'}
                            className={`w-6 text-neutral-300 ${iconSpin ? 'animate-spin' : ''} ${
                                validationState.isValid ? 'text-neutral-300' : 'text-danger'
                            }`}
                            style={
                                validationState.isValid
                                    ? {}
                                    : { color: tenantTheme.colors.danger.DEFAULT }
                            }
                        >
                            {iconEnd}
                        </span>
                    </div>
                )}

                {clearable && (valueLocal?.length ?? 0) > 0 && (
                    <div className="absolute right-2 flex h-full w-max items-center justify-center">
                        <span className={`text-neutral-300`}>
                            <IconButton
                                testId={testId ? `clear-button-${testId}` : 'clear-button'}
                                onClick={() => {
                                    if (onChange) {
                                        void onChange('');
                                        onClearButton?.();
                                    }
                                }}
                            >
                                <XMarkIcon className="h-5" />
                            </IconButton>
                        </span>
                    </div>
                )}
            </div>

            {/* Additional Description */}
            {showLabelDescription && (
                <div className="relative mt-1 flex flex-row gap-1">
                    <span
                        data-test-id={testId ? `label-description-${testId}` : 'label-description'}
                        className="text-sm text-body-light/50"
                        style={{ color: tenantTheme.colors.body.light }}
                    >
                        {labelDescription}
                    </span>
                </div>
            )}

            {/* Validation Message */}
            {!validationState.isValid && showValidation && (
                <>
                    <ValidationMessage
                        message={validationState.message ?? validationMessage}
                        testId={testId}
                    />
                </>
            )}
        </div>
    );
});

TextField.displayName = 'TextField';
